import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '@/store'
import Home from '../views/Home.vue'
import SiteList from '../views/SiteList.vue'
import SiteMenu from '../views/SiteMenu.vue'
import Staging from '../views/Staging.vue'
import StagingReview from '../views/StagingReview.vue'

Vue.use(VueRouter)

const mustBeLoggedIn = function (to, from, next) {
  console.log('-> route nav guard: rerouteHome', to, from);
  if (store.getters.sessionUser) {
    next()
  } else {
    next({name: 'Home'})
  }
}

const mustBeClockedIn = function (to, from, next) {
  console.log('-> route nav guard: rerouteHome', to, from);
  if (store.getters.sessionUserIsClockedIn) {
    next()
  } else {
    next({name: 'Home'})
  }
}

const mustBeAdmin = function (to, from, next) {
  console.log('-> route nav guard: rerouteHome', to, from);
  if (store.getters.sessionUserIsAdmin) {
    next()
  } else {
    next({name: 'Home'})
  }
}

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/sites',
    name: 'Site List',
    component: SiteList,
    beforeEnter: mustBeAdmin
  },
  {
    path: '/sites/:siteRandId',
    name: 'Site Menu',
    component: SiteMenu
  },
  {
    path: '/sites/:siteRandId/leaseholderid',
    name: 'Leaseholder ID',
    component: () => import('../views/LeaseholderID.vue')
  },
  {
    path: '/staging',
    name: 'Staging',
    component: Staging,
    beforeEnter: mustBeClockedIn
  },
  {
    path: '/staging/:stagingId',
    name: 'Staging Review',
    component: StagingReview,
    beforeEnter: mustBeClockedIn
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
