<template>
  <b-navbar toggleable="md" type="dark" variant="primary" class="shadow-sm">

    <b-navbar-toggle target="nav_collapse"></b-navbar-toggle>

    <b-navbar-brand to="/" class="text-capitalize"><img alt="Security Data Manager" src="../assets/sdm-logo-sml.png"> {{ sessionUserTopRole }} - {{ $route.name }}</b-navbar-brand>

    <b-collapse is-nav id="nav_collapse">

      <slot></slot>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto" v-if="sessionUser">
        <slot name="right"></slot>
        <b-nav-item-dropdown right no-caret>
          <template #button-content>
            <span v-if="sessionUser.avatar" class="navbar-profile-icon"><img :src="sessionUser.avatar" alt="User"></span>
            <span v-else><b-icon-person-fill/></span>
          </template>
          <b-dropdown-text>{{ sessionUser.first_name }} {{ sessionUser.last_name }}</b-dropdown-text>
          <b-dropdown-text>{{ sessionUser.email }}</b-dropdown-text>
          <b-dropdown-text>Role: {{ sessionUserRoles.join(' + ') }}</b-dropdown-text>
          <b-dropdown-divider/>
          <b-dropdown-item href="#" @click="logout">
            <b-icon-power/> Sign Out
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

    </b-collapse>

  </b-navbar>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex'

export default {
  name: 'SdmNavbar',
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'sessionUser',
      'sessionUserRoles',
      'sessionUserTopRole'
    ]),
    currentUserId () {
      return (this.currentUser) ? this.currentUser.user : null
    },
  },
  methods: {
    async logout () {
      await this.$store.dispatch('logout')
      if (this.$route.name !== 'Home') this.$router.push({ name: 'Home' })
    },
    emit: function( action ){
      this.$emit( 'navclick', action );
    }
  },
  mounted: function(){
    console.log( 'Navbar.mounted' );
  }
}
</script>

<style scoped>
.navbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  background-color: #fff;
}
.navbar-brand img {
  max-height: 27px;
}
.navbar-profile-icon {
  display: inline-block;
  margin-top: -0.5rem;
  margin-bottom: -0.5rem;
}
.navbar-profile-icon img {
  height: 2rem;
  width: auto;
  border-radius: 1rem;
}
</style>
