<template>
  <div class="site-list page with-navbar with-menu">
    <div class="main">
      <table class="table">
        <tbody>
          <tr v-for="site in sites" :key="site.id">
            <td><router-link :to="'/sites/'+site.rand_id">{{ site.name }}</router-link></td>
            <td><b-button variant="link" @click="showQR(site)"><b-icon-upc-scan></b-icon-upc-scan></b-button></td>
          </tr>
        </tbody>
      </table>
    </div>
    <sdm-navbar></sdm-navbar>
    <sdm-menu></sdm-menu>
    <b-modal size="md" ok-only ref="qrModal" :title="qrSiteName">
      <p class="text-center">
        <img alt="Site QR Code" :src="siteQRImage">
      </p>
      <p class="text-center"><a :href="siteLink">{{siteLink}}</a></p>
    </b-modal>
  </div>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex'
import SdmNavbar from '@/components/Navbar.vue'
import SdmMenu from '@/components/Menu.vue'

export default {
  name: 'SiteList',
  components: {
    SdmNavbar,
    SdmMenu
  },
  data () {
    return {
      qrSite: null
    }
  },
  computed: {
    ...mapGetters([
      'sites'
    ]),
    qrSiteName () {
      if (this.qrSite) {
        return this.qrSite.name
      }
      return '...'
    },
    siteLink () {
      if (this.qrSite) {
        return window.location.origin + '/app/sites/' + this.qrSite.rand_id
      }
      return '#'
    },
    siteQRImage () {
      if (this.qrSite) {
        return 'https://chart.googleapis.com/chart?&chs=200x200&cht=qr&chl=' + encodeURIComponent(this.siteLink)
      }
      return '#'
    }
  },
  methods: {
    ...mapActions([
      'getSites'
    ]),
    showQR (site) {
      this.qrSite = site
      this.$refs.qrModal.show()
    }
  },
  mounted () {
    console.log('SiteList.mounted')
    this.getSites()
  }
}
</script>

<style>
.site-list.page .main {
  padding: 1rem;
  background-color: #fff;
}
</style>