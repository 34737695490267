<template>
  <nav class="menu left shadow-sm">
    <h2 class="text-capitalize text-secondary"><small>{{ sessionUserTopRole }} Menu</small></h2>

    <div v-if="sessionUserTopRole==='admin'">
      <!-- Admin -->
      <ul class="nav flex-column">
        <li class="nav-item">
          <!-- <router-link class="nav-link" to="/"><b-icon-house/>Home</router-link> -->
          <a class="nav-link" href="/guard/index.php"><b-icon-house/>Home</a>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/staging">
            <b-icon-inboxes/>Staging
            <b-badge pill variant="danger" v-if="allStagedPersons.length">{{ allStagedPersons.length }}</b-badge>
          </router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/sites"><b-icon-building/>Site List</router-link>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/"><b-icon-person-circle/>Profile</router-link>
        </li>
        <li class="nav-item">
          <!-- <a class="nav-link clickable" to="#" @click.prevent="logout"><b-icon-power/>Sign Out</a> -->
          <a class="nav-link clickable" href="/guard/logout.php"><b-icon-power/>Logout</a>
        </li>
      </ul>
    </div>

    <div v-if="sessionUserTopRole==='supervisor'">
      <!-- Supervisor -->
      <ul class="nav flex-column">
        <li class="nav-item">
          <!-- <router-link class="nav-link" to="/"><b-icon-house/>Home</router-link> -->
          <a class="nav-link" href="/guard/index.php"><b-icon-house/>Home</a>
        </li>
        <li class="nav-item">
          <router-link class="nav-link" to="/staging">
            <b-icon-inboxes/>Staging
            <b-badge pill variant="danger" v-if="allStagedPersons.length">{{ allStagedPersons.length }}</b-badge>
          </router-link>
        </li>
        <!-- <li class="nav-item">
          <router-link class="nav-link" to="/sites"><b-icon-building/>Site List</router-link>
        </li> -->
        <li class="nav-item">
          <router-link class="nav-link" to="/"><b-icon-person-circle/>Profile</router-link>
        </li>
        <li class="nav-item">
          <!-- <a class="nav-link clickable" to="#" @click.prevent="logout"><b-icon-power/>Sign Out</a> -->
          <a class="nav-link clickable" href="/guard/logout.php"><b-icon-power/>Logout</a>
        </li>
      </ul>
    </div>

    <div v-if="sessionUserTopRole==='user'">
      <!-- Guard, clocked in -->
      <div v-if="sessionUserClockedInSiteId">
        <ul class="nav flex-column">
          <li class="nav-item">
            <router-link class="nav-link" to="/"><b-icon-house/>Home</router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link" :href="'/guard/manage-site.php?site_id='+sessionUserClockedInSiteId"><b-icon-shield/>Site Menu</a>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" to="/sites"><b-icon-building/>Site List</router-link>
          </li>
          <li class="nav-item">
            <!-- <a class="nav-link clickable" href="#" @click.prevent="logout"><b-icon-power/>Sign Out</a> -->
            <a class="nav-link clickable" href="/guard/logout.php"><b-icon-power/>Logout</a>
          </li>
        </ul>
      </div>

      <div v-else>
        <!-- Guard, not clocked in -->
        <ul class="nav flex-column">
          <li class="nav-item">
            <router-link class="nav-link" to="/"><b-icon-house/>Home</router-link>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/guard/view-user-schedule.php"><b-icon-clock/>My Schedule</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="/guard/manage-user-timeclock.php"><b-icon-clock/>My Timesheet</a>
          </li>
          <li class="nav-item">
            <!-- <a class="nav-link clickable" href="#" @click.prevent="logout"><b-icon-power/>Sign Out</a> -->
            <a class="nav-link clickable" href="/guard/logout.php"><b-icon-power/>Logout</a>
          </li>
        </ul>
      </div>
    </div>

  </nav>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex'

export default {
  name: 'SdmMenu',
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'sessionUserTopRole',
      'sessionUserClockedInSiteId',
      'allStagedPersons',
    ]),
  },
  methods: {
    async logout () {
      await this.$store.dispatch('logout')
      if (this.$route.name !== 'Home') this.$router.push({ name: 'Home' })
    },
  },
  mounted: function(){
    console.log( 'Menu.mounted' );
    this.$store.dispatch('getAllStagedPersons')
  }
}
</script>

<style scoped>
.menu {
  position: absolute;
  top: 56px;
  bottom: 0;
  width: 250px;
  background: #fff;
}
.menu.left {
  left: 0;
}
.menu h2 {
  margin: 0;
  font-size: 18px;
  padding: 10px 14px;
}

/* Bootstrap Overrides */
.b-icon {
  vertical-align: text-top;
  margin-right: 16px;
}

</style>
