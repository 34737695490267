import Vue from 'vue'

function asyncReadFileAsDataURL(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()
    reader.onload = () => {
      resolve(reader.result)
    };
    reader.onerror = reject;
    reader.readAsDataURL(file);
  })
}

export default {
  state: {
    stagingId: null,
    allStagedPersons: []
  },
  getters: {
    stagingId: state => state.stagingId,
    allStagedPersons: state => state.allStagedPersons,
    stagedPersonsBySite: state => {
      console.log('stagedPersonsBySite')
      const bySite = {}
      let p: number
      if (Array.isArray(state.allStagedPersons)) {
        for (p=0; p<state.allStagedPersons.length; p++) {
          if (!bySite.hasOwnProperty(state.allStagedPersons[p].site_name)) {
            bySite[state.allStagedPersons[p].site_name] = []
          }
          bySite[state.allStagedPersons[p].site_name].push(state.allStagedPersons[p])
        }
      }
      console.log('  bySite', bySite)
      return bySite
    },
    stagedPersonsByStagingId: state => {
      const byId = {}
      let p: number
      if (Array.isArray(state.allStagedPersons)) {
        for (p=0; p<state.allStagedPersons.length; p++) {
          byId[state.allStagedPersons[p].id] = state.allStagedPersons[p]
        }
      }
      return byId
    }
  },
  mutations: {
    resetSession (state) {
      state.stagingId = null
      state.allStagedPersons = []
    }
  },
  actions: {

    async initialize ({state, dispatch}) {
    },

    // Public Tenant/Leaseholder Sign-Up Functions

    async submitLeaseholderIdForm ({state, getters, commit}, payload) {
      commit('resetSessionTimers')
      console.log('store.submitLeaseholderIdForm', payload)
      const api = getters.api
      const response = await api.post('staging/persons', payload)
      console.log('  response', response)
      if (response.status === 201) {
        state.stagingId = response.data
        return state.stagingId
      }
      return false
    },

    async uploadStagingIdImage ({state, getters, commit}, file) {
      commit('resetSessionTimers')
      if (!state.stagingId) return false
      const formData = new FormData()
      formData.append("file", file)
      const api = getters.api
      const response = await api.post(`staging/persons/${state.stagingId}/id-image`, formData)
      console.log('uploadStagingIdImage response', response)
      return (response.status = 201)
    },

    async uploadStagingBadgePhoto ({state, getters, commit}, file) {
      commit('resetSessionTimers')
      if (!state.stagingId) return false
      const formData = new FormData()
      formData.append("file", file)
      const api = getters.api
      const response = await api.post(`staging/persons/${state.stagingId}/badge-photo`, formData)
      console.log('uploadStagingBadgePhoto response', response)
      return (response.status = 201)
    },

    async submitAdditionalTenants ({state, getters, commit}, addTenants: Array<AdditionalTenant>) {
      commit('resetSessionTimers')
      console.log('store.submitAdditionalTenants', addTenants)
      const api = getters.api
      const response = await api.post(`staging/persons/${state.stagingId}/additional-tenants`, addTenants)
      console.log('  response', response)
      if (response.status === 200) {
        return true
      }
      return false
    },

    // Admin Functions

    async getAllStagedPersons ({state, getters, commit}) {
      console.log('store.getAllStagedPersons')
      const api = getters.api
      const response = await api.get('staging/persons')
      commit('resetSessionTimers')
      if (response.status === 200) {
        state.allStagedPersons = response.data
        return state.allStagedPersons
      }
      return false
    },

    async getStagedPersonIdImage ({getters, commit}, stagingId: number) {
      console.log('store.getStagedPersonIdImage')
      const api = getters.api
      const response = await api.get(`staging/persons/${stagingId}/id-image.jpg`, {responseType: 'blob'})
      commit('resetSessionTimers')
      if (response.status === 200) {
        return await asyncReadFileAsDataURL(response.data)
      }
      return false
    },

    async getStagedPersonBadgePhoto ({getters, commit}, stagingId: number) {
      console.log('store.getStagedPersonBadgePhoto')
      const api = getters.api
      const response = await api.get(`staging/persons/${stagingId}/badge-photo.jpg`, {responseType: 'blob'})
      commit('resetSessionTimers')
      if (response.status === 200) {
        return await asyncReadFileAsDataURL(response.data)
      }
      return false
    },

    async findPersons ({getters, commit}, person: Person) {
      console.log('store.findSimilarPersons', person)
      const api = getters.api
      const response = await api.post(`sites/${person.site_company_id}/find-persons`, person)
      commit('resetSessionTimers')
      console.log('  response', response)
      if (response.status === 200) {
        return response.data
      }
      return []
    },

    async deleteStagedPerson ({state, getters}, person: Person) {
      console.log('store.deleteStagedPerson', person)
      const api = getters.api
      const response = await api.delete(`staging/persons/${person.id}`)
      console.log('  response', response)
      if (response.status === 200) {
        return true
      }
      return false
    },

    async approveStagedPerson ({state, getters}, person: Person) {
      console.log('store.approveStagedPerson', person)
      const api = getters.api
      const response = await api.post(`staging/persons/${person.id}/approve`, person)
      console.log('  response', response)
      if (response.status === 200 || response.status === 201) {
        return response.data
      }
      return false
    },

  },
  modules: {
  }
}
