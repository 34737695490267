


































import {mapGetters, mapMutations, mapActions} from 'vuex'
import SdmNavbar from '@/components/Navbar.vue'
import SdmMenu from '@/components/Menu.vue'
import PersonForm from '@/components/PersonForm.vue'
import AdditionalTenantsForm from '@/components/AdditionalTenantsForm.vue'

export default {
  name: 'Staging',
  components: {
    SdmNavbar,
    SdmMenu,
    PersonForm,
    AdditionalTenantsForm
  },
  data () {
    return {
      fields: [
        // { key: 'site_name', sortable: true },
        { key: 'last_name', sortable: true },
        { key: 'first_name', sortable: true },
        { key: '_created', label: 'Submitted', sortable: true },
        'additional',
        'complete',
        'controls'
      ],
      // selectedPerson: null,
      idImage: null,
      badgePhoto: null,
    }
  },
  computed: {
    ...mapGetters([
      'allStagedPersons',
      'stagedPersonsBySite'
    ]),
  },
  methods: {

  },
  mounted () {
    console.log('Staging.mounted')
    this.$store.dispatch('getAllStagedPersons')
  }
}
