<template>
  <div id="app">
    <router-view/>
    <b-modal v-model="showLogoutNotice" title="Inactivity Notice" ok-only ok-title="Keep Me Logged In" @ok="refreshSession">
      <p>You are about to be logged out due to inactivity.</p>
    </b-modal>
  </div>
</template>

<script>
export default ({
  components: {
  },
  computed: {
    showLogoutNotice: {
      get () {
        return this.$store.getters.showLogoutNotice
      },
      set (value) {}
    }
  },
  methods: {
    refreshSession () {
      this.$store.dispatch('checkCurrentSession')
    }
  },
  mounted () {
    console.log('App.mounted')
  },
})
</script>

<style>
html, body {
  width: 100%;
  height: 100%;
}
#app {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2d2d2d;
  background-color: #eee;
}

/* Layout */
.page {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.main {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
  overflow-y: auto;
}
.page.with-navbar .main {
  top: 56px;
}
.page.with-menu .main {
  left: 250px;
}

/* Other */
.clickable {
  cursor: pointer;
}
</style>
