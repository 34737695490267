<template>
  <b-container class="site-menu shadow">
    <div class="text-center">
      <p><img alt="Site logo" :src="siteLogo"></p>
      <h2>{{ currentSiteName }}</h2>
      <h1>Main Menu</h1>
    </div>

    <hr>

    <b-button class="mb-4" block variant="primary" :to="'/sites/'+currentSiteRandId+'/leaseholderid'">
      <big><b-icon icon="person-badge" aria-hidden="true"></b-icon> Lease Holder ID Sign Up</big>
    </b-button>
    <!-- <b-button class="mb-4" block variant="primary" to="/" disabled>
      <big><b-icon icon="signpost" aria-hidden="true"></b-icon> Parking Permit</big>
    </b-button> -->
    <b-button class="mb-4" block variant="primary" href="/tipline/">
      <big><b-icon icon="exclamation-triangle" aria-hidden="true"></b-icon> See Something Say Something</big>
    </b-button>

    <!-- <b-row cols="1" cols-md="2">
      <b-col>
        <b-button class="mb-4" block variant="primary" :to="'/sites/'+currentSiteRandId+'/leaseholderid'">Lease Holder ID Sign Up</b-button>
      </b-col>
      <b-col>
        <b-button class="mb-4" block variant="primary" to="leaseholderid" disabled>Additional Tenant ID Sign Up</b-button>
      </b-col>
      <b-col>
        <b-button class="mb-4" block variant="primary" to="leaseholderid" disabled>Visitor Pass</b-button>
      </b-col>
      <b-col>
        <b-button class="mb-4" block variant="primary" to="/" disabled>Parking Permit</b-button>
      </b-col>
      <b-col>
        <b-button class="mb-4" block variant="primary" to="/" disabled>Visitor Parking Pass</b-button>
      </b-col>
      <b-col>
        <b-button class="mb-4" block variant="primary" to="/" disabled>See Something Say Something</b-button>
      </b-col>
    </b-row> -->

    <hr>

  </b-container>
</template>

<script>
import {mapGetters, mapMutations, mapActions} from 'vuex'

export default {
  name: 'SiteMenu',
  components: {
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'currentSite',
      'currentSiteRandId',
      'currentSiteName'
    ]),
    siteLogo () {
      if (this.currentSite) {
        try {
          return require(`@/assets/site-${this.currentSite['id']}.jpg`);
        } catch (error) {
          return require('@/assets/sdm-logo-sml.png');
        }
      }
      return require('@/assets/sdm-logo-sml.png');
    }
  },
  methods: {
    ...mapActions([
      'loadSiteByRandId'
    ])
  },
  beforeMount () {
    console.log('SiteMenu.beforeMount', this.$route.params.siteRandId)
    this.loadSiteByRandId(this.$route.params.siteRandId)
  },
  mounted () {
    console.log('SiteMenu.mounted')
  }
}
</script>

<style>
.site-menu {
  min-height: 100%;
  background-color: #fff;
  padding-top: 15px;
}
</style>