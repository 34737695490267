





































import {mapGetters, mapMutations, mapActions} from 'vuex'
import LoginForm from '@/components/LoginForm.vue'
import SdmNavbar from '@/components/Navbar.vue'
import SdmMenu from '@/components/Menu.vue'

export default {
  name: 'Home',
  components: {
    LoginForm,
    SdmNavbar,
    SdmMenu
  },
  computed:{
    ...mapGetters([
      'sessionUser',
      'sessionUserRoles',
      'sessionUserClockedInSiteId'
    ]),
    pageClasses () {
      return (this.sessionUser) ? 'with-navbar with-menu' : ''
    }
  },
  methods: {
  },
  mounted () {
    console.log('Home.mounted')
  }
}
