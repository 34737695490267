














































import Vue from 'vue';

export default Vue.extend({
  name: 'LoginForm',
  data () {
    return {
      form: {
        'user': '',
        'pass': ''
      },
      awaitingLoginResult: false,
      loginFailed: false
    }
  },
  methods: {
    nextField () {
      this.$refs.passInput.focus()
    },
    validateForm () {
      if (!this.form.user) {
        this.$refs.userInput.focus()
        return false
      }
      if (!this.form.pass) {
        this.$refs.passInput.focus()
        return false
      }
      return true
    },
    async submitForm () {
      this.awaitingLoginResult = true
      const formValid = this.validateForm()
      if (!formValid) {
        console.warn('Could not submit form. Not all fields valid.')
        return
      }
      console.log('  Submitting...', this.form)
      const user = await this.$store.dispatch('login', this.form)
      console.log('  User:', user)
      if (user) {
        this.loginFailed = false
      } else {
        this.loginFailed = true
      }
      this.awaitingLoginResult = false
    }
  }
});
