






















































































































































































import GenericModal from '@/components/GenericModal.vue'

export default {
  name: 'AdditionalTenantsForm',
  components: {
    GenericModal
  },
  props: {
    person: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      additionalTenantTemplate: {
        'first_name':           {value: '', touched: false, required: true,  valid: null},
        'last_name':            {value: '', touched: false, required: true,  valid: null},
        'middle_name':          {value: '', touched: false, required: false, valid: null},
        'address':              {value: '', touched: false, required: false, valid: null},
        'building':             {value: '', touched: false, required: false, valid: null},
        'unit_num':             {value: '', touched: false, required: false, valid: null},
        'id_num':               {value: '', touched: false, required: false, valid: null},
        'phone':                {value: '', touched: false, required: false, valid: null},
        'email':                {value: '', touched: false, required: false, valid: null},
        'dob':                  {value: '', touched: false, required: true,  valid: null},
      },
      additionalTenants: [],
      clickedIndex: null,
      notNumbersPattern: /[^0-9]/g,
      minDob: null,
      maxDob: null
    }
  },
  methods: {
    loadPerson (newPerson?: Person) {
      let t: number, tenant: AdditionalTenant, field: string;
      if (!newPerson) newPerson = this.person;
      if (!newPerson) return;
      this.additionalTenants.splice(0);
      if (newPerson.additional_tenants) {
        for (t=0; t<newPerson.additional_tenants.length; t++) {
          tenant = JSON.parse(JSON.stringify(this.additionalTenantTemplate))
          for (field in tenant) {
            if (newPerson.additional_tenants[t][field]) {
              tenant[field].value = newPerson.additional_tenants[t][field]
            }
          }
          this.additionalTenants.push(tenant)
        }
      }
    },
    addTenant () {
      console.log('additional-tenant-form.addTenant')
      console.log('  person', this.person)
      const tenant = JSON.parse(JSON.stringify(this.additionalTenantTemplate))
      tenant.last_name.value = this.person.last_name
      tenant.address.value   = this.person.address
      tenant.building.value  = this.person.building
      tenant.unit_num.value  = this.person.unit_num
      this.additionalTenants.push(tenant)
    },
    confirmRemoveTenant (atIndex: number) {
      this.clickedIndex = atIndex
      this.$refs.genericModal.confirm("Delete this additional tenant?", "Confirm...", 'removeTenant')
    },
    removeTenant (atIndex: number) {
      this.additionalTenants.splice(atIndex, 1)
    },
    validateAdditionalTenants () {
      console.log('validateAdditionalTenants', this.additionalTenants)
      let dob: Date, allValid: boolean = true, field: string, t: number, tenant: any
      for (t=0; t<this.additionalTenants.length; t++) {
        tenant = this.additionalTenants[t]
        for (field in tenant) {
          tenant[field].touched = true
          if (tenant[field].required) {
            if (tenant[field].touched) {
              if (tenant[field].value) {
                tenant[field].valid = true
              } else {
                tenant[field].valid = false
                allValid = false
              }
            }
          }
          if (field === 'dob') {
            if (tenant[field].value) {
              dob = new Date(tenant[field].value);
              if (dob.toString() === 'Invalid Date') {
                tenant[field].valid = false
              } else if (dob.valueOf() < this.minDob || dob.valueOf() > this.maxDob) {
                tenant[field].valid = false
              } else {
                tenant[field].valid = true
              }
            } else {
              tenant[field].valid = false
              allValid = false
            }
          }
        }
      }
      return allValid
    },
    getData (): Array<AdditionalTenant> {
      const tenants = []
      let t: number, tenant: AdditionalTenant, tenantValues: any
      for (t=0; t<this.additionalTenants.length; t++) {
        tenant = this.additionalTenants[t]
        tenantValues = {}
        for (let field in tenant) {
          tenantValues[field] = tenant[field].value
        }
        tenants.push(tenantValues)
      }
      return tenants
    },

    parsePhoneNumber (phone, event) {
      console.log('parsePhoneNumber')
      if (typeof phone.value === 'number') {
        phone.value = phone.value.toString()
      }
      let value: string = phone.value.replaceAll(this.notNumbersPattern, '')
      if (value.charAt(0) === '1') {
        value = value.substr(1)
      }
      let length = value.length
      if (length<3) return;
      let areaCode = '', exchange = '', subscriber = '', extension = ''
      if (length>0) {
        areaCode = value.substr(0,3)
      }
      if (length>3) {
        exchange = value.substr(3,3)
      }
      if (length>6) {
        subscriber = value.substr(6,4)
      }
      if (length>10) {
        extension = value.substr(10)
      }
      if (extension) {
        value = `(${areaCode}) ${exchange}-${subscriber} x${extension}`
      } else if (subscriber) {
        value = `(${areaCode}) ${exchange}-${subscriber}`
      } else if (exchange) {
        value = `(${areaCode}) ${exchange}`
      }
      this.$set(phone, 'value', value)
      event.target.focus();
      event.target.setSelectionRange(value.length, value.length);
    },

    handleModalOk (okAction: string|null) {
      if (okAction === 'removeTenant') {
        this.removeTenant(this.clickedIndex)
      }
    }
  },
  mounted () {
    console.log('PersonForm.mounted')
    const now = new Date()
    this.minDob = now.valueOf() - (31557600000*120) // 120 years ago
    this.maxDob = now.valueOf() + 86400000 // today-ish
    this.loadPerson()
  },
  watch: {
    person (newPerson: Person) {
      this.loadPerson(newPerson)
    }
  }
}
