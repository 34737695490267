




































































import {mapGetters} from 'vuex'
import SdmNavbar from '@/components/Navbar.vue'
import SdmMenu from '@/components/Menu.vue'
import PersonForm from '@/components/PersonForm.vue'
import AdditionalTenantsForm from '@/components/AdditionalTenantsForm.vue'
import GenericModal from '@/components/GenericModal.vue'

export default {
  name: 'Staging',
  components: {
    SdmNavbar,
    SdmMenu,
    PersonForm,
    AdditionalTenantsForm,
    GenericModal
  },
  data () {
    return {
      idImage: null,
      badgePhoto: null,
      lastNewPersonId: null
    }
  },
  computed: {
    ...mapGetters([
      'stagedPersonsByStagingId'
    ]),
    stagingId () {
      if (this.$route.params.hasOwnProperty('stagingId')) {
        return this.$route.params.stagingId
      }
      return null
    },
    selectedPerson () {
      if (this.stagingId) {
        return this.stagedPersonsByStagingId[this.stagingId]
      }
      return null
    }
  },
  methods: {

    async loadImages () {
      console.log('loadImages', this.selectedPerson)
      this.idImage = null
      this.badgePhoto = null
      const awaiting = []
      awaiting.push( this.$store.dispatch('getStagedPersonIdImage', this.selectedPerson.id) )
      awaiting.push( this.$store.dispatch('getStagedPersonBadgePhoto', this.selectedPerson.id) )
      awaiting.push( this.$store.dispatch('findPersons', this.selectedPerson) )
      Promise.all(awaiting).then((responses) => {
        this.idImage = responses[0]
        this.badgePhoto = responses[1]
        const similarPersons = responses[2]
        console.log('  similarPersons', similarPersons)
      })
    },

    async deleteStagedPerson () {
      console.log('Staging.deleteStagedPerson')
      const finalPerson = this.$refs.personForm.getData()
      finalPerson.id_image_uploaded = (this.idImage) ? true : false;
      finalPerson.badge_photo_uploaded = (this.badgePhoto) ? true : false;
      finalPerson.additional_tenants = this.$refs.additionalTenantsForm.getData()
      console.log('  finalPerson', finalPerson)
      const success = await this.$store.dispatch('deleteStagedPerson', finalPerson)
      if (success) {
        this.$store.dispatch('getAllStagedPersons')
        this.$router.push({ name: 'Staging' })
      } else {
        // do that
      }
    },

    async approveStagedPerson () {
      console.log('Staging.approveStagedPerson')
      const finalPerson = this.$refs.personForm.getData()
      finalPerson.id_image_uploaded = (this.idImage) ? true : false;
      finalPerson.badge_photo_uploaded = (this.badgePhoto) ? true : false;
      finalPerson.additional_tenants = this.$refs.additionalTenantsForm.getData()
      console.log('  finalPerson', finalPerson)
      this.lastNewPersonId = await this.$store.dispatch('approveStagedPerson', finalPerson)
      if (this.lastNewPersonId) {
        this.$refs.gernericModal.confirm(
          "Do you want to go to the new Person Page? (e.g. to print a badge)",
          "Open Page?"
        )
      } else {
        this.$refs.gernericModal.alert(
          "Could not save to Persons database!",
          "Error"
        )
      }

    },

    handleModalOk () {
      const site_id = this.selectedPerson.site_company_id
      const pid = this.lastNewPersonId
      if (!pid) return
      window.open(`/guard/view-person.php?site_id=${site_id}&pid=${pid}`, '_blank')
    },

    handleModalCancel () {
      this.$router.push({ name: 'Staging' })
    },

  },
  mounted () {
    console.log('StagingReview.mounted')
    this.loadImages()
  }
}
