import Vue from 'vue'
import axios from 'axios';
import Cookies from 'js-cookie'

export default {
  state: {
    baseURL: (location.hostname === 'localhost') ? 'https://dev.securitydatamanager.com/api/' : '/api/',
    sessionId: null,
    api: null,
    sessionUser: null,
    sites: [],
    currentSite: null,
    showLogoutNotice: false,
    noticeTimer: null,
    logoutTimer: null
  },
  getters: {
    api: state => state.api,
    sessionUser: state => {
      return state.sessionUser
    },
    sessionUserRoles: state => {
      const roles = []
      if (state.sessionUser) {
        if (state.sessionUser.is_admin)      roles.push('admin')
        if (state.sessionUser.is_supervisor) roles.push('supervisor')
        if (state.sessionUser.is_user)       roles.push('user')
      }
      return roles
    },
    sessionUserTopRole: state => {
      if (state.sessionUser) {
        if (state.sessionUser.is_admin) return 'admin'
        if (state.sessionUser.is_supervisor) return 'supervisor'
        if (state.sessionUser.is_user) return 'user'
      }
      return 'none'
    },
    sessionUserIsAdmin: state => {
      return (state.sessionUser && state.sessionUser.is_admin)
    },
    sessionUserIsClockedIn: state => {
      return (state.sessionUser && (state.sessionUser.is_admin || state.sessionUser.is_supervisor || (state.sessionUser.is_user && state.sessionUser.hasOwnProperty('clocked_in') && state.sessionUser.clocked_in)))
    },
    sessionUserClockedInSiteId: state => {
      if (state.sessionUser) {
        if (state.sessionUser.is_user) {
          if (state.sessionUser.hasOwnProperty('clocked_in') && state.sessionUser.clocked_in) {
            return state.sessionUser.clocked_in_site_company_id
          }
          return false
        }
      }
      return null
    },
    sites: state => {
      return state.sites
    },
    sitesById: state => {
      const sitesById = {}
      for (let i=0; i<state.sites.length; i++) {
        sitesById[state.sites[i].id] = state.sites[i]
      }
      return sitesById
    },
    currentSite: state => state.currentSite,
    currentSiteRandId: (state) => {
      return (state.currentSite ? state.currentSite.rand_id : null )
    },
    currentSiteName: (state) => {
      return (state.currentSite ? state.currentSite.name : '...' )
    },
    showLogoutNotice: state => state.showLogoutNotice
  },
  mutations: {
    resetSession (state) {
      state.sessionId = null
      state.sessionUser = null
      state.sites = []
      state.showLogoutNotice = false
      if (state.noticeTimer) window.clearTimeout(state.noticeTimer)
      if (state.logoutTimer) window.clearTimeout(state.logoutTimer)
    },
    sessionId (state, newId: string) {
      console.log('set sessionId', newId)
      if (newId) {
        state.sessionId = newId
        state.api = axios.create({
          baseURL: state.baseURL,
          headers: {'Authorization': 'PHPSESSID ' + newId},
          validateStatus: null // always resolve
        });  
      } else {
        state.sessionId = null
        state.api = axios.create({
          baseURL: state.baseURL,
          validateStatus: null // always resolve
        });
  
      }
    },
    resetSessionTimers (state) {
      console.log('resetSessionTimers')
      if (!state.sessionUser) return null
      state.showLogoutNotice = false
      if (state.noticeTimer) window.clearTimeout(state.noticeTimer)
      if (state.logoutTimer) window.clearTimeout(state.logoutTimer)
      state.noticeTimer = window.setTimeout(() => {
        state.showLogoutNotice = true
      }, 300000) // 5 minutes
      state.logoutTimer = window.setTimeout(() => {
        if (state.noticeTimer) window.clearTimeout(state.noticeTimer)
        state.noticeTimer = null
        state.showLogoutNotice = false
        window.location.assign('/guard/logout.php')
      }, 600000) // 10 minutes
    },
    currentSite (state, site: any) {
      state.currentSite = site
    }
  },
  actions: {

    async initialize ({state, commit, dispatch}) {
      const sessionId = Cookies.get('PHPSESSID')
      commit('sessionId', sessionId)
      await dispatch('checkCurrentSession')
      commit('resetSessionTimers')
    },

    async pingSdm ({state}) {
      const response = await state.api.get('test/ping')
      if (response.status === 200) {
        return response.data
      }
      return false
    },

    async checkCurrentSession ({state, commit}) {
      const response = await state.api.get('auth/current')
      console.log('checkCurrentSession response', response)
      if (response.status === 200 && typeof response.data === 'object' && response.data !== null) {
        state.sessionUser = response.data
        commit('resetSessionTimers')
      } else {
        commit('resetSession')
      }
      return state.sessionUser
    },

    async login ({state, commit, dispatch}, loginForm: LoginForm) {
      const response = await state.api.post('auth/login', loginForm)
      console.log('login response', response)
      if (response.status === 200) {
        if (typeof response.data === 'string') {
          commit('sessionId', response.data)
          Cookies.set('PHPSESSID', state.sessionId)
          await dispatch('checkCurrentSession', state.sessionId)
          commit('resetSessionTimers')
        }
      }
      return state.sessionUser
    },

    async logout ({state, commit}) {
      const response = await state.api.get('auth/logout')
      console.log('logout response', response)
      Cookies.remove('PHPSESSID')
      commit('sessionId', null)
      commit('resetSession')
      return (response.status === 200)
    },

    async getSites ({state, commit}) {
      console.log('store.getSites')
      if (!state.sessionUser) return []
      if (!state.sites.length) {
        console.log('  getting latest')
        const response = await state.api.get('sites')
        if (response.status === 200 && Array.isArray(response.data)) {
          state.sites = response.data
        }
      }
      commit('resetSessionTimers')
      return state.sites
    },

    async loadSiteByRandId ({state, getters, commit}, randId: string) {
      console.log('store.loadSiteByRandId')
      const response = await state.api.get('sites/' + randId)
      if (response.status === 200) {
        commit('currentSite', response.data)
      }
      if (state.sessionUser) commit('resetSessionTimers')
      return getters.currentSite
    },

  },
  modules: {
  }
}
