











































































































































































































































































































export default {
  name: 'PersonForm',
  props: {
    person: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      form: {
        'id':                   {value: '', touched: false, required: true,  valid: null},
        'site_company_id':      {value: '', touched: false, required: true,  valid: null},
        'first_name':           {value: 'John', touched: false, required: true,  valid: null},
        'last_name':            {value: 'Doe', touched: false, required: true,  valid: null},
        'middle_name':          {value: '', touched: false, required: false, valid: null},
        'address':              {value: '1234 Main St', touched: false, required: true,  valid: null},
        'building':             {value: 'A', touched: false, required: false, valid: null},
        'unit_num':             {value: '101', touched: false, required: false, valid: null},
        'id_num':               {value: 'ABC123', touched: false, required: true,  valid: null},
        'phone':                {value: '867-5309', touched: false, required: true,  valid: null},
        'email':                {value: '', touched: false, required: false, valid: null},
        'dob':                  {value: '2000-01-01', touched: false, required: true,  valid: null},
        'contact_1_first_name': {value: 'Jane', touched: false, required: true,  valid: null},
        'contact_1_last_name':  {value: 'Doe', touched: false, required: true,  valid: null},
        'contact_1_phone':      {value: '867-5390', touched: false, required: true,  valid: null},
        'contact_2_first_name': {value: '', touched: false, required: false, valid: null},
        'contact_2_last_name':  {value: '', touched: false, required: false, valid: null},
        'contact_2_phone':      {value: '', touched: false, required: false, valid: null},
      },
      notNumbersPattern: /[^0-9]/g
    }
  },
  methods: {
    loadPerson (newPerson?: Person) {
      if (!newPerson) newPerson = this.person;
      if (!newPerson) return;
      let field: string;
      for (field in this.form) {
        this.form[field].value = (newPerson[field]) ? newPerson[field] : ''
      }
      this.$nextTick(()=>{
        this.parsePhoneNumber(this.form.phone)
        this.parsePhoneNumber(this.form.contact_1_phone)
        this.parsePhoneNumber(this.form.contact_2_phone)
      })
    },

    parsePhoneNumber (phone, event) {
      console.log('parsePhoneNumber', phone)
      if (typeof phone.value === 'number') {
        phone.value = phone.value.toString()
      }
      let value: string = phone.value.replaceAll(this.notNumbersPattern, '')
      if (value.charAt(0) === '1') {
        value = value.substr(1)
      }
      let length = value.length
      if (length<3) return;
      let areaCode = '', exchange = '', subscriber = '', extension = ''
      if (length>0) {
        areaCode = value.substr(0,3)
      }
      if (length>3) {
        exchange = value.substr(3,3)
      }
      if (length>6) {
        subscriber = value.substr(6,4)
      }
      if (length>10) {
        extension = value.substr(10)
      }
      if (extension) {
        value = `(${areaCode}) ${exchange}-${subscriber} x${extension}`
      } else if (subscriber) {
        value = `(${areaCode}) ${exchange}-${subscriber}`
      } else if (exchange) {
        value = `(${areaCode}) ${exchange}`
      }
      this.$set(phone, 'value', value)
      if (event) {
        event.target.focus();
        event.target.setSelectionRange(value.length, value.length);
      }
    },

    validateForm1 (fieldChanged: string) {
      console.log('validateForm1', fieldChanged)
      let allValid = true
      if (fieldChanged) {
        this.form[fieldChanged].touched = true
      } else {
        for (let field in this.form) {
          this.form[field].touched = true
        }
      }
      for (let field in this.form) {
        if (this.form[field].required) {
          if (this.form[field].touched) {
            if (this.form[field].value) {
              this.form[field].valid = true
            } else {
              this.form[field].valid = false
              allValid = false
            }
          }
        }
      }
      return allValid
    },

    getData (): Person {
      let field: string
      const person = {}
      for (field in this.form) {
        person[field] = this.form[field].value
      }
      // @ts-ignore
      return person
    }
  },
  mounted () {
    console.log('PersonForm.mounted')
    this.loadPerson()
  },
  watch: {
    person (newPerson: Person) {
      this.loadPerson(newPerson)
    }
  }
}
