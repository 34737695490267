import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Bootstrap-Vue Components
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assets/branding.scss'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.config.productionTip = false

// Initialize app Vue component after checking session
const PACKAGE_VERSION = require('../package.json').version
console.log("SDM v" + PACKAGE_VERSION);
store.dispatch('initialize')
.then(() => {
  const sessionUser = store.getters.sessionUser
  console.log('initializing app as', sessionUser);
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})
.catch(err => {
  console.log('initialization error', err);
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
});
