











import Vue from 'vue';

export default Vue.extend({
  name: 'GenericModal',
  data () {
    return {
      modal: {
        title: "Alert",
        content: "Something happened.",
        okOnly: true,
        okAction: null
      },
    }
  },
  methods: {

    alert (content, title, okAction) {
      this.$set(this.modal, 'title', (title)?title:"Alert");
      this.$set(this.modal, 'content', (content)?content:"Something happened.");
      this.$set(this.modal, 'okOnly', true);
      this.$set(this.modal, 'okAction', (okAction)?okAction:null);
      this.$refs.modal.show();
    },

    confirm (content, title, okAction){
      this.$set(this.modal, 'title', (title)?title:"Confirm");
      this.$set(this.modal, 'content', (content)?content:"Are you sure?");
      this.$set(this.modal, 'okOnly', false);
      this.$set(this.modal, 'okAction', (okAction)?okAction:null);
      this.$refs.modal.show();
    },

    handleModalOk () {
      this.$emit('ok', this.modal.okAction)
    },

    handleModalCancel () {
      this.$emit('cancel')
    },

  }
})
